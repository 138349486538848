import './lib/01_modernizr.min.js'
import './lib/03_lazysizes.min.js'
// import './04_magnific-popup.min.js'
import './lib/05_cssua.min.js'
import './lib/06_velocity.js'
import './lib/07_fracs.js'
import './lib/07_fracs.js'
// import './lib/extentions/scrolloverflow.js'
import './lib/08_fullpage.js'
import './lib/extentions/lazysizes.unviel.js'
import './lib/extentions/lazysizes.bgset.js'


(($, window, document) => {

  $('a[href^="mailto:"]').each( function(){
    const $this = $( this )
    if( $this.attr('target') === undefined ){
      $this.attr( 'target', '_blank' )
    }
  })

  const Mangrove = {

    /**
     * Initialize object
     * @function init
     * @memberof Mangrove
     */

    init() {

      this.cache();
      this.events();
      this.initPopups();
      this.initRtl();
      this.initCredits();
      this.scrollers();
      this.initVideos();
      this.handleCollaborators();
      this.initResources();
      this.handleInputs();
      this.handleFormStyle();
      this.handleFormActions();

    }, // init()
    /**
     * Cache reusable data
     * @function cache
     * @memberof Mangrove
     */

    cache() {

      this.$doc = $(document);
      this.$win = $(window);
      this.$html = $('html');
      this.$body = $('body');
      this.$mainWrap = $('main');
      this.$projects = $('.project-grid--block');
      this.$siteNavOverlay = $('.js-site-nav.projects--nav > div');
      this.$siteNavHrefs = $('.js-site-nav.projects--nav > ul li a');
      this.$hamburgers = $('.js-hamburger');
      this.previousBanner = '';
      this.animating = false;
      this.cached_images = 0;
      this.$footer = $('.footer:not(.footer--mobile)');
      this.$footer_mobile = $('.footer--mobile');
      this.$isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    }, // _cache()

    /**
     * Attach event listeners
     * @function _events
     * @memberof Mangrove
     */

    events() {

      this.$doc.on(
          'click',
          '.js-hamburger',
          event => this.handleTriggerSiteNav(event)
      );

      this.$siteNavOverlay.on(
          'mouseover',
          event => this.handleSiteNavMouse(event)
      );

      this.$siteNavHrefs.on(
          'mouseover',
          event => this.handleSiteNavMouse(event)
      );

      this.$siteNavHrefs.on(
          'mouseout',
          event => this.handleSiteNavMouse(event)
      );

      this.$doc.on(
          'click',
          '.js-share',
          event => this.openShareWindow(event)
      );

      this.$doc.on(
          'click',
          'body:not(.home) .js-down',
          event => this.pageNav(event)
      );

      this.$doc.on(
          'click',
          '.home footer a:not(.js-credits)',
          event => this.handleUnavailableFooterLinks(event)
      );

      this.$doc.ready(event => this.mobilize());

    }, // _events()
    openShareWindow(event) {
      let link = $(event.currentTarget).attr('href');
      if (link.substr(0, 6) != 'mailto') {
          event.preventDefault();
          window.open(link, '', 'width=600, height=400');
      }
    },

    initPopups() {
      $( ".hero--left .js-popup" ).click(function() {
        $( ".hero--left" ).removeClass('overlay-hidden');
        $( "body, html" ).addClass('popup-open');
      });
      $( ".hero--left .pop-close" ).click(function() {
        $( ".hero--left" ).addClass('overlay-hidden');
        $( "body, html" ).removeClass('popup-open');
      });
      $( ".hero--right .js-popup" ).click(function() {
        $( ".hero--right" ).removeClass('overlay-hidden');
        $( "body, html" ).addClass('popup-open');
      });
      $( ".hero--right .pop-close" ).click(function() {
        $( ".hero--right" ).addClass('overlay-hidden');
        $( "body, html" ).removeClass('popup-open');
      });
    },

    initCredits() {
      $( ".js-credits, .slide-close" ).click(function(e) {
        if (e.target == e.currentTarget) {
          $( ".credits-slide" ).toggleClass('open');
        }
      });
      $( ".building-block" ).click(function(e) {
          $( ".credits-slide" ).removeClass('open');
      });
    },
    initRtl() {
      if ($('.bio-inner').height() < 229.4) {
        $('#bio--right .wrapper').addClass('rtl');
      }
    },

    handleTriggerSiteNav(event) {
      var self = this;
      var role = $(event.currentTarget).attr('role');
      var $unTargeted = this.$hamburgers.not(`[role='${role}']`);
      var runCache = true;

      if (runCache) {
        $('.projects--nav li a').each(function(){
            var cach_image = new Image();
            cach_image.src =  $(this).attr('data-preview');
            self.cached_images++;
            if (self.cached_images == $('.projects--nav li a').length) {
              runCache = false;
            }
        });
      }

      var _resetNav = function() {
        //$('body, html').removeClass('no-scroll');
        $('[role="navigation"]').each(function() {
          $(this).removeClass('is-active');
        });
        self.$hamburgers.each(function() {
          $(this).removeClass('show-label');
          $(this).removeClass('is-active');
        });
        if (!$('.show-label').length) {
          $('.logo').css({opacity: 1});
        }
      }

      var _buildNav = function() {
        var portrait = window.matchMedia("(orientation: portrait)").maches;
        //$('body, html').addClass('no-scroll');
        if (  self.$win.width() < 500 || !portrait && self.$win.height() < 450 ) {
          _mobileNav()

        } else {
          _desktopNav()
        }
      }

      var _desktopNav = function() {
        $(event.currentTarget).toggleClass('show-label');
        $(`.${role}--nav`).toggleClass('is-active');
        $('.logo').css({opacity: 1});
      }

      var _mobileNav = function() {
        $(event.currentTarget).toggleClass('show-label');
        $unTargeted.addClass('is-active');
        $(`.${role}--nav`).toggleClass('is-active');
        $('.logo').css({opacity: 0});
      }

      event.preventDefault()
      self._resetNavStyle()
      if ( $(event.currentTarget).hasClass('show-label')
        || $(event.currentTarget).hasClass('is-active')
        || $(event.currentTarget).is('main') ) {

        _resetNav()
      } else {

        _resetNav()
        _buildNav()

      }
    },
    _resetNavStyle() {
      this.previousBanner = null;
      $('.preview--bg').velocity({opacity: 0}, {duration: 100});
      $('.preview--curtain').css({'backgroundColor': '#c8c8c8'});
      $('.preview--curtain').velocity({'opacity': 1}, 50);
      this.$siteNavHrefs.velocity({ opacity: 1 }, {duration: 200, complete: function() {
        $('.project--preview:not(.prev)').css({'backgroundImage': ''});
      }});
    },
    handleSiteNavMouse(event) {
      var self = this;
      var target = event.target;
      var currentTarget = event.currentTarget;
      var defaultBanner = $('nav ul').attr('data-default-preview');

      var _initMouseOver = function() {
        self.animating = true;

        $('.preview--bg').velocity({opacity: 1}, {duration: 75});
        $(currentTarget).velocity({ opacity: 1 }, {duration: 200, complete: function() {
          $('.preview--curtain').css({'backgroundColor': 'transparent'});
        }});

        if (self.previousBanner) {
          $('.prev').css("background-image", "url(" + self.previousBanner + ")");
        } else {
          $('.prev').css("background-image", "url(" + defaultBanner + ")");
        }

        if ($(currentTarget).attr('data-preview') && self.previousBanner !== $(currentTarget).attr('data-preview')) {
          self.previousBanner = $(currentTarget).attr('data-preview');

          $('.preview--curtain').velocity({'opacity': 0}, {duration: 150, complete: function() {
                $('.project--preview:not(.prev)')
                  .css("background-image", "url(" + $(currentTarget).attr('data-preview') + ")")
                  .css({opacity: 0})

                $('.preview--curtain').velocity({opacity: 1}, {duration: 75, complete: function() {
                            $('.project--preview:not(.prev)').velocity({opacity: 1});
                }});
              }
            }
          );
        }
        self.$siteNavHrefs.not(currentTarget).velocity({ opacity: 0.5 }, {duration: 200, complete: function() {
          self.animating = false;
        }});
      }

      var _initMouseOut = function() {
         $(currentTarget).velocity({ opacity: 1 }, 200);
      }

      if ( target == currentTarget ) {
        if (event.type == 'mouseover' && $(target).is('a')) {
          if (!self.animating) {
            _initMouseOver()
          }
        }
        if (event.type == 'mouseout') {
          _initMouseOut()
        }

      }
    },
    scrollers() {
      var self = this;
      var createFullPage = function() {
        if(self.$win.width() >= 940) {
          self.mobile = true
        } else {
          self.mobile = false
        }
        $('.home #fullpage').fullpage({
          lazyLoading: false,
          afterRender: handleAfterRender,
          afterLoad: handleAfterLoad,
          afterResize: swapFooter
        });
      }
      var handleAfterRender = function() {
        $('video').each(function(){
          $(this).trigger('play');
        })

        var firstSection = $('.section')[0];
        var firstVideo = $(firstSection).find('video');

        if (firstVideo.length) {
          $(firstVideo).css( { opacity: 0 }).animate({opacity: 1}, 'slow');
        }
      }
      var handleAfterLoad = function(link, index) {
        var loadedSection = $(this);
        var video = loadedSection.find('video');
        var $activeSlide = $('.fp-section.active');
        var $totalSlides = $('.fp-section').length

        $(video).css( { opacity: 1 });

        if ($totalSlides -1 === index) {
          //rebuild and reset before entering last slide
          $.fn.fullpage.reBuild();
          $activeSlide.next().scrollTop(0)
        }

        swapFooter()
      }
      var swapFooter = function(afterResize) {
        self.handleFormStyle()
        if (self.$win.width() >= 940 ) {
          if(self.$footer_mobile && self.mobile && !$('footer.active').length) {
            self.$footer_mobile = $('.footer--mobile').detach()
            self.$footer.appendTo('#fullpage');
            self.mobile = false;
          }

        } else {
          if(self.$footer && !self.mobile && !$('footer.active').length) {
            self.$footer = $('.footer:not(.footer--mobile)').detach()
            self.$footer_mobile.appendTo('#fullpage');
            self.mobile = true;
          }
        }
      }

      $('.home #fullpage').children().addClass('section');

      createFullPage()

      $('.home .js-down').click(function(event) {
        event.preventDefault();

        $.fn.fullpage.moveSectionDown();

      });
      $("body:not(.home)").find(".js-down").each(function() {
        if($(this).parent().next().length < 1){
          $(this).hide()
        }
      });
      $("body.home").find(".js-down").each(function() {
        if($(this).parent().parent().next().length < 1){
          $(this).hide()
        }
      });
    },
    pageNav(event) {
      var currentTarget = event.currentTarget;
      var $currentParent = $(currentTarget).parent();
      var $nextParent = $currentParent.next();
      var scrollPostion;

      if ($nextParent) {

        if ($(window).height() == $nextParent.height()) {

          scrollPostion = $nextParent.offset().top

        } else {

          scrollPostion = $nextParent.offset().top - $nextParent.height()

        }

        if ($('.page-id-137').length) {
          scrollPostion = $(window).height()
        }

        $('html, body').velocity('scroll', {
          duration: 500,
          offset: scrollPostion
        });
      }
    },
    handleUnavailableFooterLinks(event) {
      event.preventDefault();
      var target = $(event.target).attr('target');
      window.open(event.currentTarget.href, target);

    },
    mobilize() {
      var self = this;
      var isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      var $projectBanner = $('.project-banner');

      var handleFrac = function( fracs ) {
        if(fracs.visible >= 0.5 && $(this).hasClass('has-overlay')) {

          $(this).not('.disable-mobile-text').addClass('mobile-visible');
          $(this).not('.disable-mobile-text').find('.project-grid--content---inner').css({opacity: 1})

        } else {
          $(this).removeClass('mobile-visible');
          $(this).find('.project-grid--content---inner').css({opacity: 0})
        }
      };

      var loadImages = function(attr) {
        $projectBanner.attr(attr, '');
        self.$projects.each(function() {

          $(this).attr(attr, '');

        })
      }

      if (isMobile) {
          var fracs = self.$projects.fracs(handleFrac);

          loadImages('data-bg');

      } else {

          loadImages('data-bgset');

      }
    },
    handleFallBack(video, onload) {
      if (video.paused) {
         $(video).hide()
         $(video).next('.video--preview').css({opacity: 1});
      } else {
         if (onload) $(video).css( { opacity: 1 });
         $(video).next('.video--preview').css({opacity: 0});
      }
    },
    initVideos() {
      var self = this;

      $(window).on('load', function(){
        $('.section video').each(function(){
          $(this).trigger('play')
          self.handleFallBack(this)
        })
        $('.page-id-137 video, .page-id-126 video').each(function(){
          $(this).trigger('play');
          self.handleFallBack(this, true)
        })
      })
    },
    handleCollaborators() {
      const self = this;
      $('.collaborators__name').click(function(e){
        e.preventDefault();
        const targetUrl = $(this).attr('href');
        const placeholderLink = $('.collaborators__placeholder-link');
        placeholderLink.attr('href', targetUrl);
        setTimeout(() => {
          placeholderLink[0].click();
        }, self.$isMobile ? 1000 : 0);
      });
    },
    initResources() {
      $('.resources__category-posts-title').click(function () {
        $(this).closest('.resources__category-posts').toggleClass('active');
      });

      $('.resources__search-form-reset').click(function () {
        $('.resources__search-form-input').val('');
        $('.resources__search-form').submit();
      })
    },
    handleInputs() {
      if ($('.ginput_container_select').length) {

        $('.ginput_container_select').each(function() {
          const el = this;

          // const $selector = $('<span class="selector"></span>')
          const $select = $(el).find('select')

          // $selector.insertAfter($select)

          $select.on('focus', function(e) {
            $(el).addClass('is-open')
          })

          $select.on('blur change', function(e) {
            $(el).removeClass('is-open')
          })

        
          
          // $selector.on("click",function(){
          //   setTimeout(function(){
          //     $select.trigger('focus')
          //     $select.trigger('mousedown')
          //     $select.trigger('mouseenter')
          //     $select.trigger('click')
          //   })
          // })

        })

      }
      var gform_fields = ['input_2_4', 'input_2_2', 'input_2_5', 'input_2_6', 'input_2_3', 'gform_submit_button_2' ]
     
      gform_fields.forEach((element, idx) => {
        const tabIdx = 3000 + idx
        if ($(`#${element}`)) $(`#${element}`).prop('tabIndex', tabIdx)
      })
    },

    handleFormStyle() {
      // Footer form dynamic alignment
      const $heading = $('.footer__contact .gform_heading');
      const $fields_group = $('.footer__contact #gform_fields_2'); 
      const $spacer = $('.footer__social .js-footer-spacer'); 
      const space = parseInt($heading.height(), 10) + parseInt($heading.css('marginBottom'), 10) + parseInt($fields_group.css('marginTop'), 10);

      $spacer.length && $spacer.attr('style', `--height: ${space}px`)

      if (!$('.home').length) {
        $spacer.length && $spacer.attr('style', `--height: ${space}px`)
        $( window ).on( "resize", ()=> {
          const $heading = $('.footer__contact .gform_heading');
          const $fields_group = $('.footer__contact #gform_fields_2'); 
          const $spacer = $('.footer__social .js-footer-spacer'); 
          const space = parseInt($heading.height(), 10) + parseInt($heading.css('marginBottom'), 10) + parseInt($fields_group.css('marginTop'), 10);
          $spacer.length && $spacer.attr('style', `--height: ${space}px`)
        }) 
      }
    },

    handleFormActions() {
      const self = this;
      // Retain copy of form for use after submit
      $(document).on('gform_post_render', function(event, form_id, current_page){
        console.log('hello 2')
        const $form = $(`#gform_wrapper_${form_id}`).length ? $(`#gform_wrapper_${form_id}`).clone() : self.$form;
        self.$form = $form
      });
      // Append cloned form and relocate confimation message inside submit div
      $(document).on('gform_confirmation_loaded', function(event, form_id){
        !self.$form.find('#field_submit').hasClass('has-submitted') && self.$form.find('#field_submit').addClass('has-submitted').append(`<span class="confirmation-message">${gform_confirmation_message_stored}</span>`)
        $('.js-footer-form').append(self.$form)
    });
  }

  }; // Mangrove

  Mangrove.init();

})(jQuery, window, document);
